/* Infinite Slider Container */
.slider {
    display: flex;
    overflow: hidden; /* Hide overflow */
    justify-content: space-between; /* Ensure space between slides */
}

.slider-slide {
    flex: 0 0 40%; /* Ensure each slide takes full width */
    display: inline-block; /* Ensure each slide is treated as a block */
    width: 100%; /* You can adjust the width as needed */
    text-align: center; /* Center align text if needed */
    background-color: #FFF;
}


/* Slide Images */
.slider-image {
    width: 150px; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
    margin: 10px 0px 10px 0px; /* Add margin for spacing between images */
    background-color: white;
}

.slider-text {
    font-size: 50px;
    height: auto; /* Maintain aspect ratio */
    color: white
}