
#services {
    padding: 1px 0px;
    background: #eee;
    color: #fff;
}

#services .row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    /* Distribuye columnas según el espacio disponible */
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;
    /* Para que el padding no afecte el tamaño total */
    background: #EEE;
}

#services .item {
    text-align: center;
    padding: 20px;

    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

#services .container {
    background-color: #eee;
    margin: 10px auto;
    margin-top: 20px;
}

#services .service-desc {
    margin: 10px 10px 20px;
}

#services h2 {
    color: #000000;
}

#services .section-title h2::after {
    position: absolute;
    content: "";
    background: rgba(255, 255, 255, 0.3);
    height: 4px;
    width: 60px;
    bottom: 0;
    margin-left: -30px;
    left: 50%;
}

#services .icon {
    font-size: 42px;
    width: 120px;
    height: 120px;
    padding: 40px 0;
    background: #00345a;
    border-radius: 50%;
    color: #eeeeee;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}

#services h3 {
    font-weight: 500;
    padding: 5px 0;
    color: #000000;
}

#services p {
    color: #000000;
}

#services .service-desc {
    margin-bottom: 40px;
}

@media (min-width: 1100px) {
    #services .row {
        grid-template-columns: repeat(4, minmax(200px, 1fr));
    }
}

@media (min-width: 600px) and (max-width: 1099px) {
    #services .row {
        grid-template-columns: repeat(2, minmax(200px, 1fr)); /* Máximo de 3 columnas */
    }
}

@media (max-width: 599px) {
    #services .row {
        grid-template-columns: repeat(1, minmax(200px, 1fr));
    }
}