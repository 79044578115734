/* Base styles */
:root {
    --section-height: 300px;
    /* Default height for all sections, adjust as needed */
}

.page-container {
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    min-height: var(--section-height);
    border-radius: 0;
    box-sizing: border-box;
}

/*Valores section ----------------------*/

.valores {
    background-color: #FFFFFF;
    height: 70vh;
}

.valores h2 {
    font-weight: bold;
    margin: 0;
}

.valores .text-left {
    color: #000000;
    text-align: justify;
    font-size: 20px;
    line-height: 2.4;
}

.valores .image-right {
    width: 50%;
    height: 100%;
    object-fit: cover;
    object-position: center bottom;
}

/*Mission section ----------------------*/

.mission {
    background-color: #FFFFFF;
    height: 70vh;
}

.mission .text-left {
    color: #000000;
    text-align: justify;
    font-size: 15px;
    line-height: 2.4;
    margin-top: 50px;
}

.mission .image-right {
    width: 50%;
    height: 100%;
    object-fit: cover;
    object-position: center bottom;
}

.mission h2 {
    font-weight: bold;
    text-align: left;
    /* Ensure the title is left-aligned */
    margin: 0;
    margin-bottom: 20px;
}

.mission .paragraph {
    margin-bottom: 20px;
    text-align: justify;
    line-height: 2.5;
}

/*Vision section ----------------------*/

.vision {
    background-color: #FFFFFF;
    height: 60vh;
}

.vision .text-right {
    color: #000000;
    text-align: justify;
    margin-top: 0px;
    font-size: 15px;
    line-height: 2.4;
    height: auto;
}

.vision h2 {
    font-weight: bold;
    text-align: left;
    /* Ensure the title is left-aligned */
    margin: 0;
    margin-bottom: 20px;
}

.vision .text-right,
.history .text-left {
    text-align: left;
    order: 1;
}

.vision .image-left {
    width: 50%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

/*Video section ----------------------*/

.video-section {
    background-color: #979797;
}

.video-section h2 {
    font-weight: bold;
}

.video-section .video-text-left {
    color: white;
    text-align: justify;
    margin-top: -30px;
    font-size: 15px;
}

.video-text-left .paragraph {
    margin-bottom: 0px;
    text-align: justify;
    line-height: 4.0;
}

.video-text-left {
    width: 45%;
    padding: 60px 20px 100px;
}

.video-drive-inicio {
    width: 100%;
    height: 600px;
    object-fit: cover;
    object-position: center;
    align-items: center;
    padding: 0;
}

.video-drive-ab {
    width: 100%;
    height: 600px;
    object-fit: cover;
    object-position: center;
    align-items: center;
    padding: 0;
}

.video-section {
    padding-top: 0px;
    padding-bottom: 0px;
}

.video-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.video-text {
    width: 40%;
}

iframe {
    width: 560px;
    /* You can adjust this as needed */
    height: 600px;
    /* You can adjust this as needed */
    flex-shrink: 0;
    border: 0;
    /* box-shadow: 0 0 8px rgba(0,0,0,0.1); */
}

/* Everything else */

.text-left .paragraph {
    margin-bottom: 20px;
    text-align: justify;
    line-height: 2.4;
}

.image-right {
    width: 50%;
    height: 100vh;
    object-fit: cover;
    object-position: center bottom;
}

.image-left {
    width: 50%;
    height: 100vh;
    object-fit: cover;
    object-position: center bottom;
}

.text-left,
.text-right {
    width: 48%;
    padding: 60px 20px 60px;
}

.values-list {
    list-style-type: none;
    padding-left: 0;
}

.values-list li {
    margin-bottom: 25px;
    line-height: 1.5;
    font-size: 15px;
}

.values-list li strong {
    color: #000000;
    font-weight: bold;
}

.history .text-left,
.mission .text-left,
.vision .text-right {
    text-align: right;
}

/* Mobile screens */
@media (max-width: 601px) {
    section {
        flex-direction: column;
    }

    .text-left,
    .text-right,
    .image-left,
    .image-right {
        width: 100%;
        text-align: left;
        margin: 0;
    }

    .text-left,
    .text-right {
        order: 1;
        padding: 20px;
    }

    .image-left,
    .image-right {
        order: 2;
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .valores {
        height: 100%;
    }

    .mission {
        height: 100%;
    }

    .vision {
        height: 100%;
    }

    .valores .image-right {
        width: 100%;
        height: 75vh;
    }

    .mission .image-right {
        width: 100%;
        height: 75vh;
    }

    .vision .image-left {
        width: 100%;
        height: 75vh;
    }

    iframe {
        width: 100%;
        height: auto;
    }

    .values-list li {
        font-size: 14px;
        line-height: 1.8;
    }

    .mission h2,
    .vision h2 {
        text-align: left;
        /* Ensure the title is left-aligned */
    }
}

/* Tablet screens */
@media (min-width: 601px) and (max-width: 1099px) {
    section {
        flex-direction: column;
    }

    .text-left,
    .text-right,
    .image-left,
    .image-right {
        width: 100%;
        text-align: left;
        margin: 0;
    }

    .text-left,
    .text-right {
        order: 1;
        padding: 40px;
    }

    .valores {
        height: 100%;
    }

    .mission {
        height: 100%;
    }

    .vision {
        height: 100%;
    }

    .valores .image-right {
        width: 100%;
        height: 75vh;
    }

    .mission .image-right {
        width: 100%;
        height: 75vh;
    }

    .vision .image-left {
        width: 100%;
        height: 75vh;
    }

    .image-left,
    .image-right {
        order: 2;
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    iframe {
        width: 100%;
        height: auto;
    }

    .values-list li {
        font-size: 16px;
        line-height: 2.0;
    }

    .mission h2,
    .vision h2 {
        text-align: left;
        /* Ensure the title is left-aligned */
    }
}