@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: "Tenor Sans" ;
    src: url("fonts/Tenor\ Sans.ttf");
}

•⁠  ⁠{
    box-sizing: border-box;
}

html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

html, body {
    font-family: "Tenor Sans";
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #FFFFFF;
}

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

h1 {
    margin: .67em 0;
    font-size: 2em;
}

a {
    color: #337ab7;
    text-decoration: none;
}

a:hover,
a:focus {
    color: #23527c;
    text-decoration: underline;
}

a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
    font-weight: normal;
    line-height: 1;
    color: #777;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
    font-size: 65%;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin-top: 10px;
    margin-bottom: 10px;
}

h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
    font-size: 75%;
}

h1,
.h1 {
    font-size: 36px;
}

h2,
.h2 {
    font-size: 30px;
}

h3,
.h3 {
    font-size: 24px;
}

h4,
.h4 {
    font-size: 18px;
}

h5,
.h5 {
    font-size: 14px;
}

h6,
.h6 {
    font-size: 12px;
}

p {
    margin: 0 0 10px;
}
a:hover, a:visited, a:link, a:active
{
    color: $white_color;
    text-decoration: none;
}
