/* Base styles */
:root {
    --section-height: 300px; /* Default height for all sections, adjust as needed */
}

.page-container {
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    min-height: var(--section-height);
    border-radius: 0;
    box-sizing: border-box;
    margin-bottom: 50px;
    color: #001f35;
}

.Servicios h2 {
    font-weight: bold;
    margin: 0;
}

.Servicios h1 {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #001f35
}

.Servicios .text-left {
    color: white;
    text-align: justify;
    font-size: 20px;
    line-height: 2.4;
}

.Servicios .image-right {
    width: 50%;
    height: 100vh;
    object-fit: cover;
    object-position: center bottom;
}

.values-list {
    list-style-type: none;
    padding-left: 0;
}

.values-list li {
    margin-bottom: 25px;
    line-height: 1.5;
    font-size: 15px;
}

.values-list li strong {
    color: #000000;
    font-weight: bold;
}

/* Services section */
.container {
    margin: 20px; /* Center container */
    margin-top: 80px;
    margin-bottom: 0px;
    background-color: #f5f4f4;
}

.services {
    margin-top: 20px;
    margin-bottom: 30px;
}

.service-box {
    box-shadow: 0 0 4px 0 #001f35;
    padding: 15px;
    position: relative;
    margin-bottom: 50px;
}

.service-box .fa {
    font-size: 30px !important;
    margin-bottom: 20px;
    background: linear-gradient(to right, #001f35, #454347);
    -webkit-background-clip: text;
    color: transparent;
}

.service-box p {
    text-align: justify;
}

.service-box a {
    background: linear-gradient(to right, #ff105f, #ffad06);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: block;
    text-align: center;
    position: absolute;
    bottom: -20px;
    right: 20px;
}

.service-box a .fa {
    color: white;
    font-size: 25px !important;
    padding-top: 6px;
}

.container .service-row {
    display: grid;
    grid-template-columns: repeat(5, minmax(200px, 1fr));
    gap: 20px;
    box-sizing: border-box;
}



/* Mobile screens */
@media (max-width: 601px) {
    section {
        flex-direction: column;
    }

    .container {
        margin-top: 0px;
        background-color: #f5f4f4;
    }    

    .container .service-row {
        display: grid;
        grid-template-columns: repeat(1, minmax(200px, 1fr));
        gap: 20px;
        box-sizing: border-box;
    }

    .text-left, .image-right {
        width: 100%;
        text-align: left;
        margin: 0;
    }

    .image-right {
        height: 75vh;
    }

    .values-list li {
        font-size: 14px;
        line-height: 1.8;
    }

    .services h1 {
        font-size: 24px; /* Adjust heading size for mobile */
    }
}

/* Tablet screens */
@media (min-width: 601px) and (max-width: 1100px) {
    .image-right {
        height: 75vh;
    }

    .services h1 {
        font-size: 28px; /* Adjust heading size for tablet */
    }

    .container {
        margin-top: 0px;
        background-color: #f5f4f4;
    }    

    .container .service-row {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 20px;
        box-sizing: border-box;
    }
}
