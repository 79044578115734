@import "../../Stylesheets/variables.scss";

/* Contact Section */
.container {
    width: 100%;
    background-color: #979797;
    box-sizing: border-box;
}

#contact .contact-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-items: center;
    align-items: center;
    padding-top: 80px;
}

@media (max-width: 768px) {
    #contact .contact-grid {
        grid-template-columns: 1fr;
        padding-top: 50px;
    }
}

#contact .contact-item {
    text-align: center;
}

#contact .icon {
    font-size: 32px;
    color: #000000;
    margin-bottom: 10px;
}

#contact h3 {
    font-weight: 500;
    padding: 5px 0;
}

#contact p {
    color: rgba(0, 0, 0, 0.75);
    margin-top: 50px;
    margin-bottom: 100px;
    margin-left: 80px;
    margin-right: 80px;
}

#contact a {
    color: rgba(0, 0, 0, 0.75);
}

@media (max-width: 768px){
    #contact .contact-grid {
        padding-top: 50px;
    }

    #contact p {
        color: rgba(0, 0, 0, 0.75);
        margin-top: 50px;
        margin-bottom: 100px;
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media (min-width: 601px) and (max-width: 1280px){
    #contact .contact-grid {
        padding-top: 50px;
    }
}

#contact .service-desc {
    margin-bottom: 75px;
}

/* Añade estos estilos adicionales al archivo de estilos existente */

#contact .social-media-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

#contact .social-media-list li {
    margin: 0 10px;
}

#contact .social-icon {
    font-size: 24px;
    color: #000000;
    transition: transform 0.3s ease-in-out;
    margin-top: 5px;
    margin-bottom: 5px;
}

#contact .social-icon:hover {
    transform: scale(1.2);
}

#contact .social-media-list a {
    text-decoration: none;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#contact .social-media-list a span {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.75);
    margin-left: 5px;
}