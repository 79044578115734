// $Dark_color: #131416;
// $Gray_color: #6F7273;
// $white_color: #FFFFFF;
// $blue_Color1: #1C2D49;
// $blue_Color2: #3D5F7E;
// $blue_Color3: #6080A0;
// $blue_Color4: #7A9DBA;

$Dark_color: #131416;
$Gray_color: #6F7273;
$white_color: #FFFFFF;
$blue_Color1: #666666;
$blue_Color2: #3D5F7E;
$blue_Color3: #979797;
$blue_Color4: #7A9DBA;

$primary_Color1: #979797;
$primary_Color2: #666666;
$secondary_Color1: #FFAC1C;