/* Global styles to prevent overflow */
* {
    box-sizing: border-box;
}

/* Existing header styles */
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 60px;
    padding-right: 3.5%;
    background-color: #001f35; /* Solid background color when scrolled */
    /* background-color: transparent; */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    /* transition: background-color 0.3s ease; */
}

.header.solid {
    background-color: #001f35; /* Solid background color when scrolled */
    
}

.logo {
    height: 20px; 
    position: absolute; 
    /* left: 50%;  */
    /* transform: translateX(-50%);  */
    margin: 0; 
}
 
.menu {
    margin-left: auto;
    margin-right: 0;
    display: flex; /* Always display the menu */
    /*transition: color 0.3s ease; /* Smooth transition for color change */
}

.menu ul {
    list-style-type: none;
    display: flex;
    gap: 50px;
    padding: 0;
    margin: 0;
}

.menu li a {
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
    color: white;
}

.menu li a:hover {
    color: white;
}

/* Adjust color when header is solid */
.header.solid .menu li a,
.header.solid .menu li a:hover {
    color: #FFFFFF; /* White color when header is solid */
}

/* Hide hamburger icon as it's not needed anymore */
.hamburger {
    display: none;
}

/* Mobile screens */
@media (max-width: 509px) {
    .header {
        flex-direction: column;
        text-align: center;
        position: relative; 
        align-items: center; /* Center items horizontally */
        padding: 40px 20px 5px 20px;
    }

    .logo {
        position: static; 
        transform: none; 
        margin: 0 auto;
        margin-bottom: -5px;
        margin-top: -10px;
        height: 40px;
    }

    .menu {
        width: 100%; 
        margin-top: 20px; /* Adjust margin to place menu below logo */
        align-items: center;
        justify-content: center; /* Center menu items horizontally */
        position: relative;
    }

    .menu::before {
        content: "";
        display: block;
        width: 55%;
        height: 2px;
        background-color: #ddd;
        margin-bottom: 10px;
        position: absolute;
        top: -7px;
        justify-content: center;
    }

    .menu ul {
        flex-direction: column; 
        gap: 15px; 
    }

    .menu li {
        padding: 5px 0; 
    }
}

/* Tablet screens */
@media (min-width: 510px) and (max-width: 1100px) {
    .header {
        flex-direction: column;
        padding: 40px 20px 20px 20px ;
        position: relative; 
        align-items: center; /* Center items horizontally */
    }

    .logo {
        height: 50px;
        position: static; 
        transform: none; 
        margin: -10px auto 0 auto; /* Move the logo up slightly */
    }

    .menu {
        width: 100%; 
        margin-top: 0px; /* Adjust margin to place menu below logo */
        align-items: center;
        justify-content: center; /* Center menu items horizontally */
        margin-right: -7px;
    }

    .menu ul {
        flex-direction: row; 
        gap: 40px; 
    }

    .menu li {
        padding: 5px 0; 
    }
}

/* Desktop screens */
@media (min-width: 1101px) {
    .header {
        flex-direction: row;
    }

    .logo {
        height: 40px;
        /* position: absolute;  */
        /* left: 50%;  */
        /* transform: translateX(-50%);  */
        margin: 0; 
    }

    .menu {
        margin-left: auto;
        margin-right: 0;
    }

    .menu ul {
        flex-direction: row; 
        gap: 50px; 
    }

    .menu li {
        padding: 5px 0; 
    }
}
